.custom-theme-btn {
  font-family: $nunitoSans-Light;
  font-size: $fs-20px !important;
  border-radius: $fs-28px !important;
  @include setFont(500, normal, normal, normal, center);
  @include setPadding(
    $fs-12px !important,
    $fs-12px !important,
    $fs-12px !important,
    $fs-12px !important
  );
  @include setBtnColor(
    $medium-slate-blue !important,
    $medium-slate-blue !important,
    $white !important
  );
  &:hover {
    @include setBtnColor(
      $white !important,
      //background-color
      $white !important,
      //border-color
      $medium-slate-blue !important//color
    );
    border-color: $medium-slate-blue !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $white !important,
      $white !important,
      $medium-slate-blue !important
    );
    border-color: $medium-slate-blue !important;
  }
  &:disabled {
    @include setBtnColor(
      $medium-slate-blue !important,
      $medium-slate-blue !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $medium-slate-blue !important;
  }
}

.custom-danger-btn {
  font-family: $nunitoSans-Light;
  font-size: $fs-20px !important;
  border-radius: $fs-28px !important;
  @include setFont(500, normal, normal, normal, center);
  @include setPadding(
    $fs-12px !important,
    $fs-12px !important,
    $fs-12px !important,
    $fs-12px !important
  );
  @include setBtnColor(
    $danger !important,
    $danger !important,
    $white !important
  );
  &:hover {
    @include setBtnColor(
      $white !important,
      //background-color
      $white !important,
      //border-color
      $danger !important//color
    );
    border-color: $danger !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $white !important,
      $white !important,
      $danger!important
    );
    border-color: $danger !important;
  }
  &:disabled {
    @include setBtnColor(
      $danger !important,
      $danger !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $danger !important;
  }
}

.custom-btn--small {
  font-size: $fs-14px !important;
  @include setPadding(
    $fs-8px !important,
    $fs-12px !important,
    $fs-8px !important,
    $fs-12px !important
  );
}

.custom-light-blue-btn {
  font-family: $roboto-Bold;
  font-size: $fs-14px !important;
  border-radius: $fs-2px !important;
  @include setFont(500, normal, normal, normal, center);
  @include setPadding(
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important
  );
  @include setBtnColor(
    $light-blue !important,
    $light-blue !important,
    $white !important
  );
  &:hover {
    @include setBtnColor(
      $white !important,
      //background-color
      $white !important,
      //border-color
      $light-blue !important//color
    );
    border-color: $light-blue !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $white !important,
      $white !important,
      $light-blue !important
    );
    border-color: $light-blue !important;
  }
  &:disabled {
    @include setBtnColor(
      $light-blue !important,
      $light-blue !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $light-blue !important;
  }
}
