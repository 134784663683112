img {
  width: 100%;
}

.no-margin {
  @include setMargin(0 !important, 0 !important, 0 !important, 0 !important);
}

.col-no--padding {
  @include setPadding(0 !important, 0 !important, 0 !important, 0 !important);
}

a {
  &:hover {
    text-decoration: none !important;
  }
  &.disabled {
    pointer-events: none;
    cursor: default;
  }
}
.fs-10px{
  font-size: $fs-10px !important;
}

.no-mobile--margin {
  @include mobileScreen {
  @include setMargin(0 !important, 0 !important, 0 !important, 0 !important);
  }
}

.no-mobile--padding {
  @include mobileScreen {
    @include setPadding(0 !important, 0 !important, 0 !important, 0 !important);
  }
}

.container {
  max-width: 90% !important;
  margin: auto;
  padding: 0 !important;
  @include mobileScreen {
    max-width: 90% !important;
    @include desktop {
      max-width: 95% !important;
    }
  }
}

.termsandcondtion-container {
  max-width: 80% !important;
  margin: auto;
  padding: 0 !important;
  @include mobileScreen {
    max-width: 90% !important;
    @include desktop {
      max-width: 85% !important;
    }
  }
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: right;
}

.align-left {
  text-align: left !important;
}

.fw-blod{
  font-weight: bold !important;
}
.drop-shadow{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  opacity: 1;
}

.card-img{
  width: $fs-56px;
  height: 99px;
}

.position-relative{
  position: relative;
}

.form-control[type=file] {
  overflow: hidden;
  opacity: 0;
}
.container_video{
  width:100%;
  height:340px;
}

