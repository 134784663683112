.admin-panel {
  .admin-header-container {
    background-color: $white;
    height: $fs-48px;
    align-items: center;
    justify-content: space-between;
    @include setPadding(0, $fs-28px, 0, $fs-28px);
    position: fixed;
    width: 100%;
    top: 0;
    -webkit-box-shadow: 1px 0px 2px 0px rgba(50, 50, 48, 0.71);
    -moz-box-shadow: 1px 0px 2px 0px rgba(50, 50, 48, 0.71);
    box-shadow: 1px 0px 2px 0px rgba(50, 50, 48, 0.71);
    z-index: 999;
    .logo-position {
      width: $fs-38px;
    }
  }
  .content-position {
    margin-top: $fs-48px;
    .full-height {
      min-height: 80vh;
    }
    .slide-menu {
      position: absolute;
      width: 12rem;
      top: 3rem;
      background-color: $white;
      height: 100vh;
      -webkit-box-shadow: 2px 1px 2px 0px rgba(137, 190, 228, 0.5);
      -moz-box-shadow: 2px 1px 2px 0px rgba(137, 190, 228, 0.5);
      box-shadow: 2px 1px 2px 0px rgba(137, 190, 228, 0.5);
      @include mobileScreen {
        position: absolute;
        z-index: 999;
      }
    }
    .slide-collapse {
      position: absolute;
      right: -2rem;
      top: 1rem;
      z-index: 999;
    }
    .content {
      margin-left: 12rem;
      height: 100%;
      width: calc(100% - 12rem);
      @include mobileScreen {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

.menu-collapse {
  .admin-header-container {
    background-color: $white;
    height: $fs-48px;
    align-items: center;
    justify-content: space-between;
    @include setPadding(0, $fs-28px, 0, $fs-28px);
    -webkit-box-shadow: 1px 0px 2px 0px rgba(50, 50, 48, 0.71);
    -moz-box-shadow: 1px 0px 2px 0px rgba(50, 50, 48, 0.71);
    box-shadow: 1px 0px 2px 0px rgba(50, 50, 48, 0.71);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    .logo-position {
       width: $fs-38px;
    }
  }
  .content-position {
    margin-top: $fs-45px;
    .full-height {
      min-height: calc(100vh - 125px);
    }
    .slide-menu {
      position: fixed;
      width: 11%;
      margin-left: -11%;
      background-color: beige;
      height: 100vh;
      opacity: 0;
    }
    .slide-collapse {
      position: absolute;
      right: 0;
      top: $fs-16px;
      z-index: 999;
    }
    .content {
      height: 100%;
      width: 100%;
    }
  }
}

.slide-open {
  position: fixed;
  top: 4.24rem;
  left: 0rem;
  right: 0;
  margin: 0;
  width: 3rem;
}

.trapezoid {
  width: $fs-32px;
  height: $fs-38px;
  background-color: $medium-pink;
  -webkit-box-shadow: $fs-8px $fs-3px $fs-12px 0px rgba(250, 250, 250, 0.25);
  -moz-box-shadow: $fs-8px $fs-3px $fs-12px 0px rgba(250, 250, 250, 0.25);
  box-shadow: $fs-8px $fs-3px $fs-12px 0px rgba(250, 250, 250, 0.25);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-navigation {
  margin-bottom: 0;
  li {
    .main-item {
      padding: 0.5rem 0.75rem !important;
      font-size: $fs-14px !important;
      font-family: $nunitoSans-SemiBold !important;
      color: $white;
      border-left-width: 0 !important;
      border-bottom-width: $fs-1px;
      span {
        span {
          margin-left: 0.5rem !important;
          margin-right: 0.5rem !important;
          margin-top: 0.25rem !important;        }
      }
    }
  }
  .sub-nav-item {
    li {
      .sub-item {
        padding-left: 1.875rem !important;
        font-size: $fs-14px !important;
        font-family: $nunitoSans-SemiBold !important;
        color: $white;
        border-left-width: 0 !important;
      }
    }
  }
}

// .bg-gray-100,
// .hover\:bg-gray-100:hover {
//   --bg-opacity: 1;
//   background-color: #f7fafc;
//   background-color: #ffa8cf !important;
// }

.side-navigation-panel
  .side-navigation-panel-select
  .side-navigation-panel-select-wrap
  .side-navigation-panel-select-option-selected {
  background-color: $dark-pink !important;
  color: $white;
  font-family: $nunitoSans-Light !important;
  font-weight: 800 !important;
  border-left-width: $fs-2px;
  border-left-color: $white !important;
  padding: $fs-10px  $fs-16px !important;
}

.border-pink-500,
.hover\:border-pink-500:hover {
  --border-opacity: 1;
  border-color: $dark-pink!important;
  border-color: $dark-pink !important;
}

.profile-container {
  display: flex;
  align-items: center;
  width: $fs-42px;
  height: $fs-42px;
  border-radius: 50%;
  margin: auto;
  justify-content: center;
}

