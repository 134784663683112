.img_logo_audio{
    width: 30px;
    height: 40px;
    vertical-align: bottom;
}
.audioTable{
    border-collapse: separate;
    align-items: center;
    padding-left: 40pt;
    padding-right: 40pt;
    margin-left: 20px;
    margin-right: 20px;
}


.headTable{
    font-size: 24px;
    text-align: center;
    border-top: 1pt solid #cccccc;
    border-bottom: 1pt solid #cccccc;
    border-left: 1pt solid #cccccc;
    border-right: 1pt solid #cccccc;
    background-color: #f59bb0;
    vertical-align:center;
    width: 100pt;
    height:50pt;
    
}
.headTable:hover{
    background: #9ff7df;
}

    .tableEvenDialect{
        padding: 4pt;
        border-top: 1pt solid #cccccc;
        border-bottom: 1pt solid #cccccc;
        border-left: 1pt solid #cccccc;
        border-right: 1pt solid #cccccc;
        vertical-align:center;
        background-color: white;
        width: 75pt;
        height:50pt;
        text-align: center;
    }
    .tableEvenDialect:hover{
        background-color: #9ff7df;
    }
    .tableEvenAudio{
        padding-top: 15pt;
        vertical-align:center;
        border-top: 1pt solid #cccccc;
        border-bottom: 1pt solid #cccccc;
        border-left: 1pt solid #cccccc;
        border-right: 1pt solid #cccccc;
        background-color: white;
        width: 200pt;
        height:80pt;
        text-align: center;
        
    }  
    .tableEvenAudio:hover{
        background-color: #9ff7df;
    }
    .tableEvenText{
        padding-top: 4pt;
        border-top: 1pt solid #cccccc;
        border-bottom: 1pt solid #cccccc;
        border-left: 1pt solid #cccccc;
        border-right: 1pt solid #cccccc;
        vertical-align:center;
        background-color: white;
        width: 300pt;
        height:50pt;
        text-align: center;
    }     
    .tableEvenText:hover{
        background-color: #9ff7df;
    }     
    
    .tableOddDialect{
        padding: 4pt;
        border-top: 1pt solid #cccccc;
        border-bottom: 1pt solid #cccccc;
        border-left: 1pt solid #cccccc;
        border-right: 1pt solid #cccccc;
        background-color: white;
        vertical-align:center;
        width: 75pt;
        height:50pt;
        text-align: center;
    }
    .tableOddDialect:hover{
        background-color: #9ff7df;
    }
    .tableOddAudio{
        padding-top: 15pt;
        border-top: 1pt solid #cccccc;
        border-bottom: 1pt solid #cccccc;
        border-left: 1pt solid #cccccc;
        border-right: 1pt solid #cccccc;
        vertical-align:center;
        background-color: white;
        width: 200pt;
        height:80pt;
        text-align: center;
        justify-content: space-between;
    }
    .tableOddAudio:hover{
        background-color: #9ff7df;
    }
    .tableOddText{
        padding-top: 4pt;
        border-top: 1pt solid #cccccc;
        border-bottom: 1pt solid #cccccc;
        border-left: 1pt solid #cccccc;
        border-right: 1pt solid #cccccc;
        background-color: white;
        vertical-align:center;
        width: 300pt;
        height:50pt;
        text-align: center;
    }
    .tableOddText:hover{
        background-color: #9ff7df;
    }
