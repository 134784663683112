.common-select-drop-down__control {
  background-color: $white !important;
  border: solid $fs-1px $grey-light !important;
  font-size: $fs-12px;
  cursor: pointer !important;
  @include setFont(800, normal, normal, normal, left);
  min-height: $fs-28px !important;
  height: auto;
  font-family: $nunitoSans-SemiBold;
  color: $black !important;
  border-color: $grey-light !important;
  border-radius: $fs-4px;
  @include mobileScreen {
    min-height: $fs-20px !important;
    font-size: $fs-12px;
  }
}


.common-select-drop-down__option {
  @include setFont(300, normal, normal, normal, left);
  color: $black !important;
  font-size: $fs-12px !important;
  font-family: $nunitoSans-SemiBold;
  cursor: pointer !important;
  @include setPadding(
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important
  );
  &:hover {
    color: $black !important;
    background-color: transparent !important;
  }
}

.common-select-drop-down-large__option--is-focused.css-1n7v3ny-option{
  background-color: $dark-pink !important;
  color: $white !important;
 }

.common-select-drop-down__control__placeholder {
  font-family: $nunitoSans-SemiBold;
  opacity: 0.45 !important;
  font-size: $fs-13px !important;
}

.common-select-drop-down__control__control {
  border-color: $grey-light !important;
}

.common-select-drop-down__dropdown-indicator {
  padding: $fs-4px !important;
}
.common-select-drop-down__indicator {
  padding: $fs-4px !important;
}

.common-select-drop-down__option--is-selected {
  color: $white !important;
  background-color: $dark-pink !important;
}

.common-input-select-drop-down__control__control {
  min-height: $fs-38px !important;
}

.common-input-select-drop-down__control {
  background-color: $white !important;
  border: solid $fs-1px $grey-light !important;
  font-size: $fs-12px;
  cursor: pointer !important;
  @include setFont(300, normal, normal, normal, left);
  min-height: $fs-42px !important;
  height: auto;
  font-family: $nunitoSans-SemiBold;
  color: $black !important;
  border-radius: $fs-4px;
  border-color: $grey-light !important;
  @include mobileScreen {
    min-height: $fs-38px !important;
    font-size: $fs-12px;
  }
}

.common-input-select-drop-down__control__placeholder {
  font-family: $nunitoSans-SemiBold;
  opacity: 0.45 !important;
  font-size: $fs-13px !important;
}

.common-input-select-drop-down__control__single-value {
  font-family: $nunitoSans-SemiBold;
  color: $black !important;
  font-weight: 800 !important;
}

.common-input-select-drop-down__control__option {
  @include setFont(300, normal, normal, normal, left);
  color: $black !important;
  font-size: $fs-12px !important;
  font-family: $nunitoSans-SemiBold;
  cursor: pointer !important;
  @include setPadding(
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important
  );
  &:hover {
    color: $black !important;
    background-color: transparent !important;
  }
}

.common-input-select-drop-down__control__menu {
  z-index: 9 !important;
}

.common-select-drop-down-large__control {
  background-color: $white !important;
  border: solid $fs-1px $grey-light !important;
  font-size: $fs-15px;
  cursor: pointer !important;
  @include setFont(800, normal, normal, normal, left);
  min-height: $fs-36px !important;
  height: auto;
  font-family: $nunitoSans-SemiBold;
  color: $black !important;
  border-color: $grey-light !important;
  border-radius: $fs-4px;
  @include mobileScreen {
    min-height: $fs-36px !important;
    font-size: $fs-12px;
  }
}

.common-select-drop-down-large__option {
  @include setFont(300, normal, normal, normal, left);
  color: $black !important;
  font-size: $fs-14px !important;
  font-family: $nunitoSans-SemiBold;
  cursor: pointer !important;
  @include setPadding(
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important
  );
  &:hover {
    color: $black !important;
    background-color: transparent !important;
  }
}

.common-select-drop-down-large__control__placeholder {
  font-family: $nunitoSans-SemiBold;
  opacity: 0.45 !important;
  font-size: $fs-13px !important;
}

.common-select-drop-down-large__control__control {
  border-color: $grey-light !important;
}

.common-select-drop-down-large__dropdown-indicator {
  padding: $fs-4px !important;
}
.common-select-drop-down-large__indicator {
  padding: $fs-4px !important;
}
.common-select-drop-down-large__option--is-selected {
  color: $white !important;
  background-color: $dark-pink !important;
}

.common-select-drop-down-large__single-value--is-disabled {
  font-family: $nunitoSans-SemiBold;
  color: $black !important;
}
.common-select-drop-down-large__placeholder {
  opacity: 0.45 !important;
  font-size: $fs-13px !important;
}

/// Meterial UI Compoennt

// .menu-open__container {
//   @include setPadding(0 !important, 0 !important, 0 !important, 0 !important);
//   .MuiButton-label {
//     font-family: $nunitoSans-SemiBold;
//     @include setFont(500, normal, normal, normal, center);
//     @include setMargin(0, 0, 0, 0);
//     @include setPadding($fs-2px, $fs-6px, $fs-2px, $fs-6px);
//     font-size: $fs-15px !important;
//     color: $white;
//     text-transform: capitalize;
//     &:focus {
//       box-shadow: none !important;
//       outline: none !important;
//     }
//     &:active {
//       box-shadow: none !important;
//     }
//   }
//   &:focus {
//     box-shadow: none !important;
//     outline: none !important;
//   }
//   &:active {
//     box-shadow: none !important;
//   }
//   &.menu-open--small {
//     border: 1px solid $grey-light;
//     padding: $fs-4px $fs-4px !important;
//     width: 8rem;
//     .MuiButton-label {
//       font-size: $fs-12px;
//       color: $charcoal-grey;
//       display: flex;
//       justify-content: space-between;
//       text-transform: capitalize;
//     }
//   }
//   &.menu-open--large {
//     border: 1px solid $grey-light;
//     padding: $fs-6px $fs-8px !important;
//     width: 8rem;
//     .MuiButton-label {
//       font-size: $fs-12px;
//       color: $charcoal-grey;
//       display: flex;
//       justify-content: space-between;
//       text-transform: capitalize;
//     }
//   }
// }

// .menu-open-item__contianer {
//   top: $fs-16px !important;
//   .MuiMenu-paper {
//     max-height: calc(100% - 20px) !important;
//     width: 14rem;
//     border-radius: $fs-6px;
//   }
//   .menu-open-item {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     border-bottom: $fs-2px solid $grey-white-smoke !important;
//     @include setPadding(
//       $fs-8px !important,
//       $fs-6px !important,
//       $fs-8px !important,
//       $fs-6px !important
//     );
//     min-height: auto !important;
//     &:last-child {
//       border-bottom: none !important;
//     }
//   }
//   &.menu-open-item--small {
//     top: $fs-45px !important;
//     .MuiMenu-paper {
//       width: 10rem;
//     }
//   }
//   &.menu-open-item--large {
//     top: $fs-45px !important;
//     position: relative;
//     .MuiMenu-paper {
//       top: 150px !important;
//       max-height: calc(60% - 100px) !important;
//       // min-height: calc(100% - 100px) !important;
//       overflow-x: hidden;
//       overflow-y: auto;
//     }
//   }
//   @include mobileScreen {
//     &.menu-open--mobile-postion {
//       .MuiMenu-paper {
//         left: 0 !important;
//         right: 0 !important;
//         margin: auto;
//         width: 70% !important;
//         top: 20% !important;
//       }
//       .menu-open-item {
//         @include setPadding(
//           $fs-14px !important,
//           $fs-6px !important,
//           $fs-14px !important,
//           $fs-6px !important
//         );
//       }
//     }
//   }
// }


.common-select-drop-down.common-select-drop-down-large__menu.css-26l3qy-menu {
  max-height: 200px;
  overflow: auto;
}
