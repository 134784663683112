.section-title {
  font-family: $nunitoSans-Light;
  color: $nero-grey;
  @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
  line-height: $fs-28px !important;
}
.welcome-header {
  font-family: $nunitoSans-Light;
  color: #4E4B40;
  @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
  line-height: $fs-28px !important;
}
.sub-section-title {
  font-family: $nunitoSans-Regular;
  color: $charcoal-grey;
  @include setFont(500, normal, normal, normal, left);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
  line-height: $fs-28px !important;
}
.banner-padding{
  padding-top: 5rem !important;
}
.title-white {
  color: $white;
}
.title-black {
  color: $black;
}
.title-dim-grey {
  color: $dim-grey;
}
.title-blue {
  color: $blue;
}
.title-charcoal-grey {
  color: $charcoal-grey;
}
.title-dark-light-grey {
  color: $dark-light-grey;
}
.line-height-20px {
  line-height: $fs-20px !important;
}
.line-height-35px {
  line-height: $fs-35px !important;
}
.line-height-54px {
  line-height: $fs-54px !important;
}
.line-height-48px {
  line-height: $fs-48px !important;
}
.nunitoSans-SemiBold {
  font-family: $nunitoSans-SemiBold !important;
}
.nunitoSans-Regular {
  font-family: $nunitoSans-Regular !important;
}
.nunitoSans-Black {
  font-family: $nunitoSans-Black !important;
}
.nunitoSans-Bold {
  font-family: $nunitoSans-Bold !important;
}
.strip-no-padding {
  @include setPadding(0!important, 0!important, 0!important, 0!important);
}
.bg-madison-blue {
  background-color: $madison-blue;
}
.bg-solitude-blue {
  background-color: $solitude-blue;
}
.bg-white-smoke-light {
  background-color: $white-smoke-light;
}
.bg-white-smoke-medium{
  background-color: $white-smoke-medium;
}
.main-header-container {
  position: fixed;

}
.header-container {
  position: fixed;
  width: 100%;
  height: 3rem;
  display: table;
  align-items:flex-end;
  background: $white;
  justify-content: center;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 0px #00000029;
  @include mobileScreen {
    align-items: center;
    justify-content: flex-end;
    padding-right: 2rem;
    padding-left: 2rem;
  }
  &.mega-menu--hide {
    position: relative;
    opacity: 0 !important;
  }
}
.menu-container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
font-size: 24px;
}
.spirelab-img--width {
  width: 7rem;
}
.spire-lab-img--width {
  object-fit: contain;
  width: 2.5rem;
}
.custom-link--position {
  font-family: $nunitoSans-Light;
  font-size: $fs-14px;
  cursor: pointer;
  text-transform: capitalize;
  padding-bottom: 0.35rem;
  .custom-link {
    @include setPadding(0, $fs-12px, $fs-4px, $fs-10px);
    color: $nero-grey !important;
    cursor: pointer;
    &:hover {
      color: $medium-pink !important;
      cursor: pointer;
      border-bottom: $fs-2px solid $medium-pink !important;
    }
    &.custom-link-active {
      cursor: pointer;
      font-family: $nunitoSans-SemiBold;
      font-weight: 600;
      padding-bottom: 0;
      border-bottom: $fs-3px solid $medium-pink !important;
      color: $medium-pink !important;
    }
  }
}
.custom-link--mobile-position {
  font-family: $nunitoSans-Light;
  font-size: $fs-18px;
  text-transform: capitalize;
  cursor: pointer;
  .custom-link {
    width: 100%;
    display: flex;
    @include setPadding($fs-16px, $fs-40px, $fs-16px, $fs-32px);
    color: $nero-grey !important;
    cursor: pointer;
    &:hover {
      color: $medium-pink !important;
      cursor: pointer;
      border-bottom: $fs-3px solid $medium-pink !important;
    }
    &.custom-link-active {
      cursor: pointer;
      font-family: $nunitoSans-SemiBold;
      font-weight: 600;
      border-bottom: $fs-3px solid $medium-pink !important;
      color: $medium-pink !important;
    }
  }
}
.home-title__container {
  position: absolute !important;
  padding-top: 2.5rem;
  width: 60% !important;
  margin: auto;
  left: 0;
  right: 0;
  text-align: center;
  @include mobileScreen {
    width: 80% !important;
  }
  @include desktop-max {
    width: 60vh !important;
  }
  @include hdRes {
    width: 80% !important;
  }
}
.home-mobile-img--position {
  @include mobileScreen {
    height: 40vh;
  }
  @include desktop-max {
    height: 35vh;
  }
  height: 60vh;
}
.social-icon-width {
  width: $fs-40px;
  cursor: pointer;
}
.photos-card-br {

  padding-top: $fs-30px;
  padding-left: $fs-24px;
  padding-right: $fs-24px;
  &:nth-child(2n) {
    .opening-card {
      border-top: $fs-5px solid rgb(144, 141, 255);
      box-shadow: 0 0 11px rgba(33,33,33,.2); 
      color: #000000;
      //background: #c7e2f5 0% 0% no-repeat padding-box;
      
  }
  }

  &:nth-child(2n+1) {
    .opening-card {
      border-left: $fs-5px solid #22be9a;
      box-shadow: 0 0 11px rgba(33,33,33,.2); 
      color: #000000;
    //  background: #f5e4f5 0% 0% no-repeat padding-box;
      
    }
  }
   &:hover {
       cursor: pointer;
       box-shadow: 0 0 11px rgba(33,33,33,.2); 
        //background: linear-gradient( #f3d5dc , #bfffee );
   }
       
  
}
.mediaimg-card-br {
  display: block;
  padding-top: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  align-items: center;
}


.photosbox-bg {
   background-color: #f3f3f3;
  //background: transparent linear-gradient(180deg, #ebdee2 0%, #f0d7ed 100%) 0% 0% no-repeat padding-box;
}

.gallery-card-img-container {
  width: 100%;
  display: flex;
  align-items: center;
  .img-item {
    width: 100%;
    height: 300px;
    object-fit: cover;
    @include mobileScreen {
      height: 250px;
      @include desktop {
        height: 300px;
      }
    }
  }
}
.img-item--position{
  width: 100%;
  height: 300px;
  object-fit: contain;
  @include mobileScreen {
    height: 250px;
    @include desktop {
      height: 300px;
    }
  }
}
.about-us-text__container {
  @include mobileScreen {
    margin: auto !important;
  }
}
.about-us-description {
  text-align: left !important;
  @include mobileScreen {
    text-align: center !important;
    padding-top: $fs-32px !important;
  }
}
.academics-img-container {
    position: relative;
    // width: 11rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    @include mobileScreen {
      margin: auto;
    }
    .first-circle-container {
      position: absolute;
      width: 7rem;
      height: 7rem;
      border-radius: 50%;
      opacity: 1;
    }
    .second-circle-container {
      position: absolute;
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      opacity: 0.2;
    }
    .third-circle-container {
      position: absolute;
      width: 7rem;
      height: 7rem;
      border-radius: 50%;
      opacity: 0.2;
    }
    &:hover {
      border: none !important;
    }
    &:focus {
      border: none !important;
    }
}
.team-img-container {
  position: relative;
  width: 11rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  &:hover {
    cursor: pointer;
  }
  @include mobileScreen {
    margin: auto;
  }
  .first-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 1;
  }
  .second-circle-container {
    position: absolute;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    opacity: 0.2;
  }
  .third-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 0.2;
  }
 
}
.mobile-contactus-padding{
  @include mobileScreen {
  padding-top: 8.5rem!important;
  }
}
.popup-team-img-container1 {
  position: relative;
  width: 18rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: auto;
 
  @include mobileScreen {
    margin: auto;
  }
  .first-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 1;
  }
  .second-circle-container {
    position: absolute;
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    opacity: 0.2;
  }
  .third-circle-container {
    position: absolute;
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    opacity: 0.2;
  }
}
.academics-people {
  
  &:nth-child(3n + 1) {
    .first-circle-container {
      border: 15px solid #675BE8;
    }
    .third-circle-container {
      border: 15px solid #675BE8;
    }
  }
  &:nth-child(3n + 2) {
    .first-circle-container {
      border: 15px solid #675BE8;
    }
    .third-circle-container {
      border: 15px solid #675BE8;
    }
  }
  &:nth-child(3n + 3) {
    .first-circle-container {
      border: 15px solid #675BE8;
    }
    .third-circle-container {
      border: 15px solid #675BE8;
    }
  }
}
.team {
  &:nth-child(3n + 1) {
    .first-circle-container {
      border: 15px solid rgb(254, 213, 130);
    }
    .second-circle-container {
      border: 15px solid rgb(254, 213, 130);
    }
  }
  &:nth-child(3n + 2) {
    .first-circle-container {
      border: 15px solid rgb(103, 91, 232);
    }
    .second-circle-container {
      border: 15px solid rgb(103, 91, 232);
    }
  }
  &:nth-child(3n + 3) {
    .first-circle-container {
      border: 15px solid rgb(253, 122, 145);
    }
    .second-circle-container {
      border: 15px solid rgb(253, 122, 145);
    }
  }
}
.footer-text {
  text-align: center;
  @include mobileScreen {
    font-size: $fs-16px !important;
    text-align: center;
  }
}
.mobile-footer--position {
  @include mobileScreen {
    margin: auto;
  }
}
.mobile-header {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 0;
  min-height: 48vh;
  background: $white;
  z-index: 999;
  padding-top: $fs-18px;
  padding-left: $fs-24px;
  padding-right: $fs-24px;
  box-shadow: 0px 0px 6px #00000029;
}
.menu-icon {
  width: $fs-22px;
  height: $fs-14px;
  position: absolute;
  right: $fs-22px;
  top: $fs-18px;
}
.logo-icon {
  width: $fs-32px;
  height: $fs-22px;
  position: absolute;
  left: $fs-12px;
  top: $fs-8px;
}

.close-icon {
  width: 2rem;
  height: $fs-28px;
  position: absolute;
  right: $fs-16px;
  top: $fs-16px;
}


.full-height {
  height: 100vh;
}
.box-shadow {
  box-shadow: 0 0 $fs-1px 0 rgba(0, 0, 0, 0.16);
  -webkit-box-shadow: 0 0 $fs-1px 0 rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0 0 $fs-1px 0 rgba(0, 0, 0, 0.75);
  border-radius: $fs-4px;
}
.custom-reference-links {
  font-family: $nunitoSans-Regular;
  color: $blue !important;
  @include setFont(normal, normal, normal, normal, center);
  line-height: $fs-28px;
  text-decoration: underline;
  font-size: $fs-14px !important;
  white-space: normal;
  word-break: break-all;
  &:hover {
    text-decoration: underline !important;
  }
}
img {
  &:focus {
    outline: none !important;
  }
}
.custom-link-item {
  color: $nero-grey !important;
}
.fw-800 {
  font-weight: 800;
}
.mobile-text-position {
  text-align: left;
}
.contant-email--title {
  @include mobileScreen {
    font-size: $fs-24px !important;
    @include xsmall {
      font-size: $fs-16px !important;
    }
    @include phone {
      font-size: $fs-16px !important;
    }
    @include iphone {
      font-size: $fs-16px !important;
    }
    @include phablet {
      font-size: $fs-16px !important;
    }
  }
}
.mb-12px {
  margin-bottom: $fs-12px;
}
.pl-12px {
  padding-left: $fs-12px;
}
.pr-12px {
  padding-right: $fs-12px;
}
.br-line {
  height: $fs-28px;
  border: 1px solid $white-smoke;
}
.iisc-logo--width {
  width: $fs-38px;
}
.full-width {
  width: 100%;
}
.right-icon--width {
  width: $fs-26px;
  cursor: pointer;
}
.opening-card-br {
  font-size: $fs-14px !important;
  &:nth-child(even) {
    .opening-card {
      border-left: $fs-5px solid $medium-slate-blue;
    }
  }
  &:nth-child(odd) {
    .opening-card {
      border-left: $fs-5px solid $macaroni-orange;
    }
  }
  &:nth-child(2n + 2) {
    .opening-card {
      border-left: $fs-5px solid $razzle-rose;
    }
  }
}
.docsbox-bg{
  background-color: rgb(239, 240, 236);;
}
.docs-card-br {
  font-size: $fs-14px !important;
  &:nth-child(even) {
    .docs-card {
      border-left: $fs-5px solid $medium-slate-blue;
    }
  }
  &:nth-child(odd) {
    .docs-card {
      border-left: $fs-5px solid $macaroni-orange;
    }
  }
  &:nth-child(2n + 2) {
    .docs-card {
      border-left: $fs-5px solid $razzle-rose;
    }
  }
}

.previous-challenges-card-br {
  font-size: $fs-14px !important;
  &:nth-child(1n+1) {
    .opening-card {
      border-top: $fs-5px solid $pattens-blue;
    }
  }
  &:nth-child(2n+2) {
    .opening-card {
      border-top: $fs-5px solid $pattens-blue;
    }
  }
  &:nth-child(3n+3) {
    .opening-card {
      border-top: $fs-5px solid $pattens-blue;
    }
  }
}
.challenges-card-br {
  &:nth-child(1n+1) {
    .opening-card {
      border-top: $fs-5px solid $medium-slate-blue;
      font-size: $fs-18px !important;
    }
  }
  &:nth-child(2n+2) {
    .opening-card {
      border-top: $fs-5px solid $macaroni-orange;
    }
  }
  &:nth-child(3n+3) {
    .opening-card {
      border-top: $fs-5px solid $razzle-rose;
    }
  }
}
.opening-card-position {
  min-height: $fs-56px;
  padding: 0 $fs-10px;
}
.opening-card-position-date {
  padding: 0 $fs-10px;
}
.document-card-position {
  // min-height: 2.875rem;
  top: 265px;
  left: 274px;
  height: 45px; 
}
.contact-icon--width {
  width: $fs-18px;
}
.contact-icon-email---width {
  width: $fs-20px;
}
.map-container {
  height: 52vh;
  @include mobileScreen {
    height: 30vh;
  }
  @include desktop-max {
    height: 30vh;
  }
}

.map-padding{
  padding-bottom: 40px;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: 20px;
}

.custom-table-thead {
  tr {
    font-family: $nunitoSans-Bold;
    background-color: $dark-pink;
    color: $white;
    font-size: $fs-14px;
    text-align: center;
  }
}
.custom-btn-small {
  @include setPadding(
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important,
    $fs-4px !important
  );
}
.custom-edit-btn{
  box-shadow: 0px $fs-8px $fs-6px #72C3DB29;
  font-family: $nunitoSans-Bold;
  font-size: $fs-14px !important;
  border-radius: $fs-4px !important;
  @include setFont(500, normal, normal, $fs-1px, center);
  @include setPadding(
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important,
    $fs-8px !important
  );
  @include setBtnColor(
    $kelly-green !important,
    $kelly-green !important,
    $white !important
  );
  &:hover {
    @include setBtnColor(
      $white !important,
      //background-color
      $white !important,
      //border-color
      $kelly-green !important//color
    );
    border-color: $kelly-green !important;
  }
  &:focus {
    box-shadow: none !important;
    @include setBtnColor(
      $white !important,
      $white !important,
      $kelly-green !important
    );
    border-color: $kelly-green !important;
  }
  &:disabled {
    @include setBtnColor(
      $kelly-green !important,
      $kelly-green !important,
      $white !important
    );
    cursor: not-allowed;
    border-color: $kelly-green !important;
  }
}
.fw-600 {
  font-weight: 600;
}
.map-loader {
  padding: $fs-32px;
  text-align: center;
}
.maps-position {
  left: 0;
  right: 0;
  width: "100%";
  height: "100%";
}
.dot-icon {
  font-size: $fs-14px !important;
  color: #FD4B9A !important;
  // fill: $dark-grey !important;
  margin-top: $fs-6px !important;
  margin-right: $fs-4px !important;
}
.opening-info-container {
  border: $fs-1px solid $macaroni-orange;
  padding: $fs-18px;
  border-radius: $fs-4px;
}
.text-capitalize {
  text-transform: capitalize;
}
.min-height-auto {
  min-height: auto;
  @include mobileScreen {
    min-height: auto !important;
  }
}
.team-mobile-position {
  margin-right: auto;
  @include mobileScreen {
    margin: auto;
    @include desktop {
      margin: auto;
    }
  }
}
.iisc-img--width {
  width: 70%;
  margin: auto;
}
.mobile--footer--logo-position {
  @include mobileScreen {
    justify-content: center;
    margin-bottom: $fs-16px;
  }
}
.mega-menu-container {
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  background: transparent linear-gradient(249deg, #ff8b8e 0%, #fa85b8 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  @include setPadding($fs-16px, $fs-16px, $fs-16px, $fs-16px);
  @include mobileScreen {
    overflow-y: scroll;
    @include setPadding($fs-16px, $fs-16px, $fs-16px, $fs-16px !important);
    position: fixed;
    width: 100%;
  left: 0;
  right: 0;
  top: 0;
    height: 100%;
  }
  &.mega-menu--show {
    opacity: 1 !important;
  }
  &.mega-menu--hide {
    position: absolute;
    top: -11rem;
    opacity: 0 !important;
    z-index: 999;
  }
}
.position {
  position: absolute;
}
.mega-menu-link--position {
  font-family: $nunitoSans-Regular;
  font-size: $fs-12px ;
  cursor: pointer;
  text-transform: capitalize;
  padding-bottom: 0.015rem;
  @include mobileScreen {
    font-size: $fs-15px;
  }
  .mega-menu-link {
    width: 100%;
    display: flex;
    @include setPadding($fs-2px, $fs-2px, $fs-2px, $fs-2px);
    width: 224px;
    color: $white;
    cursor: pointer;
    @include mobileScreen {
      @include setPadding($fs-3px, 0, $fs-3px, 0);
    }
    &:hover {
      color: $white !important;
      cursor: pointer;
    }
    &.mega-menu-link-active {
      cursor: pointer;
      font-family: $nunitoSans-Black;
      font-weight: 800;
      color: $white !important;
    }
  }
}
.mega-menu-title {
  font-family: $nunitoSans-Bold;
  color: $white !important;
  font-size: $fs-14px !important;
  @include setFont(500, normal, normal, normal, left);
  @include setMargin(0, 0, 8px, 0);
  @include setPadding(0, 0, 0, 0);
  @include mobileScreen {
    font-size: $fs-18px;
  }
}
.mega-menu-logo {
  width: 2rem;
}
.mega-menu-close-icon--container {
  position: relative;
  right: $fs-28px;
  top: -0.5rem;
  width: 0px;
  height: 35px;
  opacity: 1;
}
.mega-menu-close-icon {
  width: 2rem;
}

.document-section-title {
  font-family: $nunitoSans-Regular;
  color: $nero-grey;
  opacity: 1;
  letter-spacing: 0px;
  font-size: $fs-15px;
  @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
  line-height: $fs-20px !important;
}
.document-sub-section-title {
  font-family: $nunitoSans-Regular;
  color: $Matterhorn;
  letter-spacing: 0px;
  font-size:  14px !important ;
  @include setFont(500, normal, normal, normal, left);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
  line-height: $fs-12px !important;
}
.media-card-position {
  min-height: $fs-30px;
  // padding: 0 $fs-10px;
}
.media-links {
  text-decoration: none;
  // border-bottom: #EA215A 0.125em solid;
}
.media-section-title {
  font-family: $nunitoSans-Light;
  color: $blue;
  @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
  line-height: $fs-28px !important;
}
.researcharea-bg {
  // background-color: #796476;
  background: transparent linear-gradient(180deg, #ff6894 0%, #f44ce1 100%) 0%
    0% no-repeat padding-box;
}
.area-card-br {
  &:nth-child(n+1) {
    .opening-card {
      border-top: $fs-5px solid $medium-slate-blue;
      color: #FFFFFF;
      background: transparent linear-gradient(180deg, #ffbe86 0%, #ff988c 100%)
        0% 0% no-repeat padding-box;
    }
  }
  &:nth-child(3n+3) {
    .opening-card {
      border-top: $fs-5px solid $macaroni-orange;
      color: #FFFFFF;
      background: transparent linear-gradient(180deg, #ff6894 0%, #f44ce1 100%)
        0% 0% no-repeat padding-box;
    }
  }
  &:nth-child(2n+2) {
    .opening-card {
      border-top: $fs-5px solid $razzle-rose;
      color: #FFFFFF;
      background: transparent linear-gradient(180deg, #565de9 0%, #9c55e5 100%)
        0% 0% no-repeat padding-box;
    }
  }
}
.txt-underline {
  text-decoration: underline; 
}
.arrow-img--container {
  width: $fs-28px;
  padding: 0.15rem;
  cursor: pointer;
  $color: white;
}
.text-underline {
  text-decoration: underline;
}
.play-icon-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.pdf-thumbnail-container {
  position: absolute;
  top: 3px;
  right: 2px;
  background: $crimson-red;
}
.trash-icon-container {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  background: $crimson-red;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
}
.gallery-img {
width: 320px;
height: 240px;
// background: transparent 0% 0% no-repeat padding-box;
// opacity: 1;
}
.img-viewer-container{
  height: 500px;
  width: 100%;
  object-fit: contain;
}
.image-modal-container {
  @include setPadding($fs-16px, $fs-16px, $fs-16px, $fs-16px);
  width: 50% !important;
  @include desktop-max {
    width: 80% !important;
  }
  @include mobileScreen {
    width: 90% !important;
  }
}
// .next-prev-container {
//   color: #9c55e5;
//   @include mobileScreen {
//     left: -0.75rem !important;
//   }
// }
.documents-border{
  box-shadow: 0px 0px 3px #00000029;
}
.pdf-thumbnail-style {
  width:20px !important;
  height: 20px !important;
}
#tst-strip-openings > div > div > div > div.pb-4.pt-4.col-12.col-sm-12.col-md-12.col-lg-12.col-xl-12 > div > div > div > div:nth-child(1) > div > div {
    width: 96px !important;
  height: 99px !important;
}
#tst-strip-openings > div > div > div > div.pb-4.pt-4.col-12.col-sm-12.col-md-12.col-lg-12.col-xl-12 > div > div > div > div:nth-child(1) > div > div > div > canvas{
  width:100% !important;
  height: 99px !important;
}
.project-title-container{
  width:200px;
  height: 165px;
}
// #tst-strip-gallery > div > div.pb-5.px-0.m-auto.col-12.col-sm-12.col-md-12.col-lg-11.col-xl-11 > div > div > div > div.slick-list > div > div:nth-child(2){
//   width: 657px !important;
// }
.aboutus-img {
  width: 600px;
height: 320px;
}

.object-fit_fill { object-fit: fill }
.object-fit_contain { object-fit: cover }
.object-fit_cover { object-fit: cover }
.object-fit_none { object-fit: none }
.object-fit_scale-down { object-fit: scale-down }
.gal-img { width: 320px; height: 240px; background-color: #F7F7F8; }
.photos-img { width: 320px; height: 240px; background-color: #F7F7F8; padding: 8pt;}
.image {
  float: left;
  
  &:nth-child(2n) {
    clear: left;
  }
  
  &:nth-child(2n+1){
    margin-right: 0;
  }
}
.mobile-gal-img {
  width: 174px; height: 131px; background-color: #F7F7F8;
}
.opening-card:hover {
  background:  #a8c7fb21;// #C7DBFC;
}

.tab-container {
  border: none !important;
  flex-wrap: nowrap !important;
  @include mobileScreen {
    justify-content: space-between;
    @include desktop {
      justify-content: flex-start;
    }
  }
  .tab-nav-item {
    .tab-nav-link {
      border: none !important;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      color: $black !important;
      font-size: $fs-16px !important;
      font-family: $nunitoSans-Regular !important;
      padding-bottom: $fs-8px;
      border-bottom: $fs-4px solid $transparent !important;
      @include setFont(500, normal, normal, normal, left);
      cursor: pointer;
      background-color: $transparent;
      @include mobileScreen {
        padding-left: $fs-6px !important;
        padding-right: $fs-6px !important;
        font-size: $fs-14px !important;
        @include desktop {
          padding-left: $fs-14px !important;
          padding-right: $fs-14px !important;
        }
      }
      &.active {
        border: none !important;
        color: $black !important;
        font-family: $nunitoSans-Bold !important;
        border-bottom: $fs-4px solid $medium-pink !important;
        @include setFont(500, normal, normal, normal, left);
        background-color: $transparent;
        // &:hover {
        //   border-bottom: $fs-4px solid $medium-pink !important;
        // }
      }
    }
    &:hover {
      border: none !important;
    }
    &:focus {
      border: none !important;
    }
  }
}
.foo{
  cursor: pointer;
  color: #C4C4C5;
}
.search-input-field {
  height: 33px !important;
  border-radius: 17px !important;
  background-color: #F1F1F1 !important;
}
.search-btn {
  height: 33px;
  /* margin: 0px; */
  font-size: 14px;
  margin-left: 3px;
  margin-right: 3px;
  padding-left: 16px;
  padding-right: 17px;
  border: none;
  border-radius: 17px;
  color: #FFFFFF;
  background-color: #FD4B9A;
}

.contribute-sort {
  text-align: left;
font: normal normal 300 16px/30px Nunito Sans;
letter-spacing: 0px;
color: #292929;
opacity: 1;
font-size: 16px !important;
}
.contributors-style {
  // text-align: center;
font: normal normal 600 25px/95px Nunito Sans;
letter-spacing: 0px;
color: #292929;
opacity: 1;
}

.contributors-place-style {
text-align: left;
font: normal normal normal 18px/95px Nunito Sans;
letter-spacing: 0px;
color: #292929;
opacity: 1;
}

.table-heading {
  font: normal normal 600 25px/95px Nunito Sans;
}

.contributors-card-br {
  font-size: $fs-14px !important;
}

.contributors-card-position {
  min-height: $fs-8px;
  padding: 0 $fs-4px;
}
.academics-big-img{
  @media screen and (min-device-width : 640px) {
    width: 1200px;
    height: 500px;
    background: #F8F7F7 0% 0% no-repeat padding-box;
    opacity: 1;
  }

}
.a-tag-container {
  width: 100%;
  text-align: center;
}
.a-tag {
  display: inline-block;
}
.large-img-container {
  width: 1000px;
  height: 500px;
  @include mobileScreen {
    width: 310px;
    height: 300px;
  }
}
.large-img {
  width: 100%;
  height: 100%;
  @include mobileScreen {
    width: 100%;
    height: 100%;
  }
}
.pdf-thumbnail {
  @include mobileScreen {
  width: 80px;
  height: 82px;
  @include setPadding(0, 0, 0, 0);
  }
}
.documents-text {
  @include mobileScreen {
  padding-left: 25pxpx !important;
  }
}

#tst-strip-documents > div > div > div > div.pb-4.pt-4.col-12.col-sm-12.col-md-12.col-lg-12.col-xl-12 > div > div > div > div.pdf-thumbnail.pt-2.pb-2.col-6.col-sm-6.col-md-5.col-lg-5.col-xl-5 > div > div > div > canvas {
  // @media only screen and (max-width: 768px) {
  width: 100px !important;
  height: 100px !important;
  }
  
#tst-strip-openings > div > div > div > div.pb-4.pt-4.col-12.col-sm-12.col-md-12.col-lg-12.col-xl-12 > div > div > div > div.pdf-thumbnail.pt-2.pb-2.col-6.col-sm-6.col-md-5.col-lg-5.col-xl-5 > div > div > div > canvas {
  @media only screen and (max-width: 768px) {
  width: 86px !important;
  height: 75px !important;
  }
}

.publications-card-br {
  font-size: $fs-14px !important;
}

.publications-card-position {
  min-height: $fs-8px;
  padding: 0 $fs-4px;
}

.tab-container-publications {
  border: none !important;
  flex-wrap: nowrap !important;
  @include mobileScreen {
    justify-content: space-between;
    @include desktop {
      justify-content: flex-start;
    }
  }
  .publications-tab-link {
    .publications-tab-link {
      border: none !important;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      color: $black !important;
      font-size: $fs-16px !important;
      font-family: $nunitoSans-Regular !important;
      padding-bottom: $fs-8px;
      border-bottom: $fs-4px solid $transparent !important;
      @include setFont(500, normal, normal, normal, left);
      cursor: pointer;
      background-color: $transparent;
      @include mobileScreen {
        padding-left: $fs-6px !important;
        padding-right: $fs-6px !important;
        font-size: $fs-14px !important;
        @include desktop {
          padding-left: $fs-14px !important;
          padding-right: $fs-14px !important;
        }
      }
      &.active {
        border: none !important;
        color: $black !important;
        font-family: $nunitoSans-Bold !important;
        border-bottom: $fs-4px solid $medium-pink !important;
        @include setFont(500, normal, normal, normal, left);
        background-color: $transparent;
        // &:hover {
        //   border-bottom: $fs-4px solid $medium-pink !important;
        // }
      }
    }
    &:hover {
      border: none !important;
    }
    &:focus {
      border: none !important;
    }
  }
}

.tools-card-br {
  
  &:nth-child(1n+1) {
    .tools-card {
      border-top: $fs-5px solid #7C59E7;
      font-size: $fs-18px !important;
    }
  }
  &:nth-child(2n+2) {
    .tools-card {
      border-top: $fs-5px solid #F44CE1;
    }
  }
  &:nth-child(3n+3) {
    .tools-card {
      border-top: $fs-5px solid #FEBB86;
    }
  }
  
  &:hover {
    cursor: pointer;
  }
}

.tools-card-position { 
  min-height: $fs-32px;
  background: transparent linear-gradient(180deg, #F5F8FA 0%, #F5F8FA 100%)
  
}
// .opening-card-position-date {
//   padding: 0 $fs-10px;
// }

.tools-info-container {
  // border: $fs-1px solid $dim-grey;
  background-color: #F5F8FA;
  padding: $fs-10px;
}

.datasets-card-br {
  
  &:nth-child(1n+1) {
    .datasets-card {
      border-top: $fs-5px solid #7C59E7;
      font-size: $fs-18px !important;
    }
  }
  &:nth-child(2n+2) {
    .datasets-card {
      border-top: $fs-5px solid #F44CE1;
    }
  }
  &:nth-child(3n+3) {
    .datasets-card {
      border-top: $fs-5px solid #FEBB86;
    }
  }
  &:nth-child(4n+4) {
    .datasets-card {
      border-top: $fs-5px solid #59e77c;
    }
  }

 
  &:hover {
    cursor: pointer;
  }
}

.datasets-card-position { 
  min-height: $fs-32px;
  background: transparent linear-gradient(180deg, #F5F8FA 0%, #F5F8FA 100%)
}
// .opening-card-position-date {
//   padding: 0 $fs-10px;
// } 

.datasets-info-container {
  // border: $fs-1px solid $dim-grey;
  background-color: #F5F8FA;
  padding: $fs-10px;
}

.challenges-card-img-container {
  width: 100%;
  display: flex;
  align-items: center;
  .img-item {
    width: 50%;
   // height: 300px;
    object-fit: fill;
    @include mobileScreen {
      width: 50%; //100%;
    //  height: 200px;
      @include desktop {
        width: 40%;
      //  height: 400px;
        object-fit: cover;
      }
    }
  }
}

.areas-card-img-container {
  width: 100%;
  display: flex;
  align-items: center;
  .img-item {
    width: 100%;
    height: 300px;
    object-fit: fill;
    @include mobileScreen {
      width: 100%;
      height: 200px;
      @include desktop {
        width: 40%;
        height: 400px;
        object-fit: cover;
      }
    }
  }
}

.react-tabs {
  display: flex;
  margin-left: 36px;
  width: 470px;
  height: 400px;
  border: 1px solid #3c3e43;
  color: white;
  background: #1c1d1f;
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 170px;
  margin: 0;
  padding: 0;
  color: white;
  background: #3c3e43;
}

.react-tabs__tab {
  height: 70px;
  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  color: #bbb;
}

.react-tabs__tab--selected {
  background: #1c1d1f;
  border-color: #1c1d1f;
  border-left: 4px solid #6eb800;
  color: white;
}

.react-tabs__tab-panel {
  display: none;
  width: 300px;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  padding-left: 24px;
}

.react-tabs__tab--selected {
  padding-left: 21px;
}

.panel-content {
  text-align: center;
}
.opening-warn {
  color: red;
}
.opening-warn-slide {
  background:#f3e7e7;
  padding:0.4rem;
  overflow: hidden;
}
.opening-warn-font{
  font-weight: bold;
  font-size: 1.5rem !important;
}
.opening-warn-notice {
  animation : opening-warn-slide 15s linear infinite;
  
}

@keyframes opening-warn-slide {
  0% {
    transform: translatex(65%)
  }
  // 50% {
  //   transform: translatex(0%)
  // }
  100% {
    transform: translatex(0%)
  }
}

.textimagemaps{
  border: 2px solid #000000;
}
.imagemaps{
  padding-left: 4.5rem!important;
}