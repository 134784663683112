.internal-section--title {
  font-family: $nunitoSans-Light;
  color: $nero-grey;
  @include setFont(700, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}

.bg-dark-pink {
  background-color: $dark-pink !important;
}

.search__form-container {
  border: none !important;
  border-bottom: $fs-3px solid $dark-pink !important;
  border-radius: 0 !important;
  font-family: $nunitoSans-Light;
  padding-left: $fs-30px !important;
  padding-right: $fs-40px !important;
  color: $nero-grey !important;
  @include setFont(800, normal, normal, normal, left);
  &:focus {
    outline: none !important;
    box-shadow: none !important;
    border-bottom-color: $dark-pink !important;
  }
  &::placeholder {
    opacity: 0.45 !important;
    font-size: $fs-12px !important;
  }
}

.search__icon-container {
  align-items: center !important;
  .search__icon_append {
    position: absolute;
    z-index: 99;
  }
  .search__icon-position {
    background-color: transparent !important;
    padding: 0.25rem !important;
    border: none !important;
  }
}

.reset_label {
  position: absolute;
  right: $fs-4px;
  z-index: 99;
  color: $dark-pink !important;
  cursor: pointer !important;
}

.edit-img-file {
  position: absolute;
  right: $fs-5px;
  border-radius: 50%;
  width: $fs-24px;
  height: $fs-24px;
  background: $danger;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.312rem;
  cursor: pointer;
}

.img-thumbnail-container {
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
}

.dropzone {
  &:focus {
    border: none !important;
    outline: 0 !important;
  }
}

.bg-pink {
  background-color: $dark-pink;
}

.title-white {
  color: $white;
}

.confirmation-content__container {
  padding-top: 1rem !important;
  padding-bottom: 0rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.bg-light-white {
  background-color: $grey-light;
}

.title-danger {
  color: $danger;
}

.cursor-pointer {
  cursor: pointer;
}

.loader-position {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fs-14px {
  font-size: $fs-14px !important;
}
.rdt_TableHeader {
  display: none !important;
}

.br-4px {
  border-radius: $fs-4px !important;
}

.br-grey{
  border: 1px solid $white-smoke;
}

.width-64px{
  width: $fs-48px;
}

.terms-condition-section-title {
  font-family: var(--bs-family-Jost-Medium);
   @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}
.terms-condition-navy-blue-medium {
  color: $navy-blue-medium;
}
.terms-condition-section-title {
  font-family: var(--bs-family-Jost-Medium);
  color: $nunitoSans-Black;
  @include setFont(500, normal, normal, normal, center);
  @include setMargin(0, 0, 0, 0);
  @include setPadding(0, 0, 0, 0);
}
.title-navy-blue-medium {
  color: $navy-blue-medium;
}

.jostLight {
  font-family: $jostLight;
}
.lh-25px {
  line-height: $fs-25px !important;
}
ol.alpha-order {
  .alpha {
    list-style: lower-alpha;
  }
}
ol {
  @include mobileScreen {
    padding-left: $fs-12px !important;
  }
}

.terms-condition-align-left {
  text-align: var(--bs-align-left);
}