/*--------------------------------------------------------------
	Common
--------------------------------------------------------------*/
@import "screen-sizes";
@import "mixins";
@import "fonts";
@import "colors";

:root {
  font-size: $fs-16px; // 16px standard size
  // Control the font size across all screens based on
  // root font size
  //xsmall is for screen size less than 320px
  @include xsmall {
    font-size: $fs-13px;
  }
  //phone is for screen sizes 320px to 409px
  @include phone {
    font-size: $fs-17px;
  }
  //iphone is from 410px to 499px
  @include iphone {
    font-size: $fs-20px;
  }
  //phablet is from 500px to 649px
  @include phablet {
    font-size: $fs-20px;
  }
  //tablet is from 650px to 767px
  @include tablet {
    font-size: $fs-20px;
  }
  //desktop is from 768px to 1023px
  @include desktop {
    font-size: $fs-20px;
  }
  //ipad is from 1024px to 1365px
  @include iPad {
    font-size: $fs-18px;
  }
  //hdRes is from 1366px to 1919px
  @include hdRes {
    font-size: $fs-22px;
  }
  //hdResSmall is from 1920px to 2435px
  @include hdResSmall {
    font-size: $fs-22px;
  }
  //hdResMedium is from 2436px to 3838px
  @include hdResMedium {
    font-size: $fs-38px;
  }
  //hdResLarge is from 3840px
  @include hdResLarge {
    font-size: $fs-50px;
  }
}

html {
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: scrollbar;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: $white !important;
  -webkit-box-shadow: 0 0 0px 1000px $white inset;
  transition: background-color 5000s ease-in-out 0s;
}


//scrollbar

.scoll-bar::-webkit-scrollbar {
  width: $fs-4px;
  height: $fs-4px;
  background-color: $transparent;
}
.scoll-bar::-webkit-scrollbar-thumb {
  background-color: $dark-pink;
}
.scoll-bar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 $fs-2px $dark-pink !important;
  box-shadow: inset 0 0 $fs-2px $dark-pink !important;
  background-color: $transparent;
}

::-webkit-scrollbar {
  width: $fs-5px;
  height: $fs-5px;
  background-color: $transparent;
}
::-webkit-scrollbar-thumb {
  background-color: $dark-pink;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 $fs-2px $dark-pink !important;
  box-shadow: inset 0 0 $fs-2px $dark-pink !important;
  background-color: $transparent;
}

.team-img-container{
    img {
      border-radius: 50%;
      object-fit: cover;
      width: 6rem;
      height: 6rem;
      @include desktop {
        border-radius: 50%;
        object-fit: cover;
        width: 6rem;
        height: 6rem;

      }
 }
}
.popup-team-img-container {
  img {
    border-radius: 50%;
    object-fit: cover;
    width: 6rem;
    height: 6rem;
    @include desktop {
      border-radius: 50%;
      object-fit: cover;
      width: 6rem;
      height: 6rem;

    }
}
}
.column_design {
  background-color: lightgray;
  margin-bottom: 2% !important;
  border-radius: $fs-8px !important;
  @include mobileScreen {
    background-color: lightgray;
  margin-bottom: 2% !important;
  border-radius: $fs-8px !important;
  font-size: $fs-16px;
  min-width: $fs-6px !important;
  }
}
.single_column_design {
  // border: 2px solid lightgray;
  padding: 0.65rem !important;
  line-height: 1.5 !important;
  // font-size: 0.85rem;
  text-align: left;
  font: normal normal 300 16px/95px Nunito Sans;
  letter-spacing: 0px;
  color: #292929;
  opacity: 1;
  // border-radius: $fs-4px !important;
}
.header_width {
  min-width: 200px !important;
  font-size: 0.85rem;
  padding-left: 10px;

}
.column_design1 {
  background-color: #F7F8FF;
  margin-bottom: 1% !important;
  // border-radius: $fs-8px !important;
  min-width: $fs-352px;
  @include mobileScreen {
    background-color: #F7F8FF;
    margin-bottom: 1% !important;
    // border-radius: $fs-8px !important;
    min-width: $fs-176px;

  }
}
.row-header-width {
  /* width: 37px; */
  // min-width: 40px !important;
  // font-size: 0.85rem;
  text-align: left;
  font: normal normal 600 18px/18px Nunito Sans;
  letter-spacing: 0px;
  color: #292929;
  opacity: 1;
}
tr:nth-child(odd) {
  background-color: #F7F7F7 !important;
}