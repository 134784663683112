$madison-blue: #2c3e50;
$white: #ffffff;
$black: #000000;
$nero-grey: #292929;
$transparent: "transparent";
$dark-pink: #e76293;
$dim-grey: #6f6f6f;
$medium-slate-blue: #645be8;
$medium-pink: #fd4b9a;
$danger: #dc3545;
$warning: #e88a02;
$grey: #dedede;
$grey-light: #dedede;
$blue: #408BF9;
$kelly-green: #2FB40E;
$white-smoke: #dfdfdf;
$medium-slate-blue: #7C59E7;
$macaroni-orange: #FEBB86;
$solitude-blue: #F5F8FA;
$razzle-rose: #F44CE1;
$gainsboro-white: #D8D8D8;
$charcoal-grey: #4D4D4D;
$light-blue: #765AE8;
$dark-grey: #888888;
$dark-light-grey: #707070;
$white-smoke-light: #F7F7F7;
$pattens-blue:#D7ECFA;
$Matterhorn:#575656;
$white-smoke-medium: #FAFAFA;
$crimson-red: #DC0D45;
$black-pearl: #050708;
$navy-blue-medium: #1C2040;
$jostLight: Jost-Light;
           

